import {
  Box,
  Typography,
  CircularProgress,
  Pagination,
  Stack,
  Chip,
} from "@mui/material";
import style from "../styles/financialOverlay";
import { AllocationType, OverlayTableSectionProps } from "../types";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { salary_expense_warning } from "../constants";

const OverlayTableSection: React.FC<OverlayTableSectionProps> = ({
  currentPage,
  count,
  header: { name, totalHeading, tableHeading1, tableHeading2, tableHeading3 },
  showLoader,
  data,
  handlePageChange,
  showDetails = false,
  totalAmount,
  disableDetails,
  toggleIsShowDetails,
  showWarning,
}) => {
  const allocationTypeColors: Record<AllocationType, string> = {
    Available: " #49AEFB",
    Trainee: "#F5B55A",
    Billable: "#008CF7",
    Internal: "#52D209",
    Shadow: "#6B6D6A",
  };

  return (
    <Box sx={style.tableContainer}>
      <Typography color="text.primary" sx={{ ...style.heading, ...style.mb4 }}>
        {name}
      </Typography>
      {showWarning && (
        <Box sx={style.warningBox}>
          <Stack sx={{ alignItems: "center" }} direction={"row"}>
            <InfoOutlinedIcon sx={style.warningIcon} />
            <Typography color={"#3E3F3F"} fontWeight={400} fontSize={"12px"}>
              {salary_expense_warning}
            </Typography>
          </Stack>
        </Box>
      )}

      <Box
        sx={{
          ...style.tableContainer.row,
          ...style.tableContainer.header,
          alignItems: "center",
          mb: "8px",
        }}
      >
        <Box sx={style.totalAmountContainer}>
          <Typography
            color="text.primary"
            sx={style.totalAmountContainer.totalText}
          >
            {totalHeading}
          </Typography>
          <Typography
            color="text.primary"
            sx={[
              style.totalAmountContainer.totalAmount,
              totalAmount === "NA" ? { fontSize: "14px" } : {},
            ]}
          >
            {totalAmount}
          </Typography>
        </Box>
        <Typography
          color="text.primary"
          sx={[
            style.totalAmountContainer.hideText,
            disableDetails
              ? style.totalAmountContainer.hideText["&.disabled"]
              : {},
          ]}
          onClick={(event) => {
            if (!showDetails) {
              handlePageChange(event, 1);
            }
            toggleIsShowDetails();
          }}
        >
          {showDetails ? "Hide Details" : "Show Details"}
        </Typography>
      </Box>
      {showDetails && !disableDetails && (
        <Box>
          {data && data.length > 0 ? (
            <Box
              sx={{
                ...style.tableContainer.row,
                ...style.tableContainer.header,
                mb: "8px",
              }}
            >
              <Typography
                color="text.primary"
                sx={{ ...style.detailTitle, ...style.flex_space }}
              >
                {tableHeading1}
              </Typography>
              <Typography
                color="text.primary"
                sx={{ ...style.detailTitle, ...style.flex1 }}
                textAlign="left"
              >
                {tableHeading2}
              </Typography>
              <Typography
                color="text.primary"
                sx={{
                  ...style.detailTitle,
                  ...style.flex1,
                  textAlign: "end",
                }}
              >
                {tableHeading3}
              </Typography>
            </Box>
          ) : (
            <Box sx={style.tableContainer.row}></Box>
          )}
          <Box sx={style.relativePosition}>
            {showLoader && (
              <Box sx={style.circularLoaderBox}>
                <CircularProgress
                  size={20}
                  sx={style.circularLoaderBox.loader}
                />
              </Box>
            )}
            {data && data.length > 0
              ? data.map((row, index) => (
                  <Box
                    key={index}
                    sx={[
                      style.tableContainer.row,
                      {
                        backgroundColor:
                          index % 2 === 0 ? "#F2F6FC" : "#FCFCFC",
                      },
                    ]}
                  >
                    <Box
                      sx={{
                        ...style.flex_space,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        color="text.primary"
                        sx={{ ...style.detailText }}
                      >
                        {row.column1}
                      </Typography>
                      <Box
                        sx={{ display: "flex", flexWrap: "wrap", ml: "8px" }}
                      >
                        {row.allocationTypes &&
                          (typeof row.allocationTypes === "string" ? (
                            <Typography
                              color="text.primary"
                              sx={{ ...style.detailText, ...style.flex1 }}
                              textAlign="left"
                            >
                              {row.allocationTypes}
                            </Typography>
                          ) : (
                            row.allocationTypes.map((label, labelIndex) => (
                              <Chip
                                key={labelIndex}
                                label={label}
                                size="small"
                                sx={{
                                  ...style.labelChip,
                                  backgroundColor:
                                    allocationTypeColors[
                                      label as keyof typeof allocationTypeColors
                                    ] || "#E0E0E0",
                                  "& .MuiChip-label": {
                                    fontSize: "8px",
                                    paddingX: "1px",
                                  },
                                }}
                              />
                            ))
                          ))}
                      </Box>
                    </Box>
                    <Typography
                      color="text.primary"
                      sx={{ ...style.detailText, ...style.flex1 }}
                      textAlign="left"
                    >
                      {row.column2}
                    </Typography>
                    <Typography
                      color="text.primary"
                      sx={{
                        ...style.detailText,
                        ...style.flex1,
                        textAlign: "end",
                      }}
                    >
                      {row.column3 != null ? row.column3 : "NA"}
                    </Typography>
                  </Box>
                ))
              : !showLoader && (
                  <Typography
                    color="text.primary"
                    sx={[style.detailText, { textAlign: "center" }]}
                  >
                    {`No ${name} Available`}
                  </Typography>
                )}
          </Box>
          <Box sx={{ my: "8px" }}>
            {count && count > 1 ? (
              <Pagination
                count={count}
                page={currentPage}
                onChange={(event, newPage) => handlePageChange(event, newPage)}
                size="small"
                variant="outlined"
                shape="rounded"
                sx={{
                  "& .MuiPaginationItem-root": {
                    color: "gray",
                    "&.Mui-selected": {
                      backgroundColor: "#4E4E4E",
                      color: "white",
                    },
                  },
                  "& .MuiPaginationItem-icon": {
                    color: "#E6292E",
                  },
                }}
              />
            ) : (
              ""
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default OverlayTableSection;
