import React from "react";
import { useTheme, styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import { useNavigate } from "react-router-dom";

import { useData } from "../contexts/DataContext";
import { useApp } from "../contexts/AppContext";

import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";

dayjs.extend(isToday);
dayjs.extend(isYesterday);

const drawerWidth = 300;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(0),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export default function PersistentDrawerLeft({ children }: { children: any }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const { dashboardList } = useData();
  const { drawerOpen, handleDrawerClose } = useApp();

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const toggleSection =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const goToDashboard = (id: number) => {
    navigate(`/dashboard/${id}`);
  };

  const dashboardsBySection: any = dashboardList.reduce(
    (acc: any, dashboard: any) => {
      if (!acc[dashboard.section]) {
        acc[dashboard.section] = [];
      }
      acc[dashboard.section].push(dashboard);
      return acc;
    },
    {}
  );

  const drawer = () => (
    <Drawer
      sx={{
        display: "block",
        position: "sticky",
        height: "calc(100vh - 60px)",
        width: drawerWidth,
        flexShrink: 0,
        py: theme.spacing(2),
        ...(drawerOpen && { pl: theme.spacing(2) }),
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          border: "none",
          position: "relative",
          backgroundColor: "#f8f7fc",
          overflowX: "hidden",
          scrollbarWidth: "thin",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.06) inset`,
            backgroundColor: "#DEDEDE",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#BEBEBE",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#BEBEBE",
          },
        },
      }}
      variant="persistent"
      anchor="left"
      open={drawerOpen}
      onClose={handleDrawerClose}
    >
      <Box sx={{ width: drawerWidth }} role="presentation">
        {Object.entries(dashboardsBySection)
          .sort(([sectionA], [sectionB]) => {
            if (sectionA === "General Lists") return -1;
            if (sectionB === "General Lists") return 1;
            return 0;
          })
          .map(([section, sectionDashboards]: any, idx) => (
            <Accordion
              key={section}
              expanded={expanded === `panel${idx}`}
              onChange={toggleSection(`panel${idx}`)}
              disableGutters={true}
              style={{ marginBottom: "5px", border: "none", boxShadow: "none" }}
            >
              <AccordionSummary
                expandIcon={
                  `panel${idx}` ? (
                    <img src="images/down-arrow.svg" alt="less" />
                  ) : (
                    <img src="images/up-arrow.svg" alt="more" />
                  )
                }
                aria-controls={`panel${idx}bh-content`}
                id={`panel${idx}bh-header`}
                style={{ marginBottom: "5px", border: "none", height: "68px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      width: "100%",
                      flexShrink: 0,
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                    variant="h6"
                  >
                    {section}
                  </Typography>
                  <Typography
                    sx={{
                      width: "100%",
                      flexShrink: 0,
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    variant="subtitle1"
                  >
                    {sectionDashboards.length} Dashboards
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px" }}>
                {sectionDashboards.map((dashboard: any) => (
                  <div key={dashboard.id}>
                    <Box
                      sx={{
                        height: "4px",
                        backgroundColor: "#f8f7fc",
                        flexGrow: 1,
                      }}
                    ></Box>
                    <Button
                      variant="text"
                      key={dashboard.id}
                      onClick={() => goToDashboard(dashboard.id)}
                      sx={{
                        flexDirection: "column",
                        textTransform: "capitalize",
                        alignItems: "flex-start",
                        textAlign: "left",
                        width: "100%",
                        height: "68px",
                        paddingLeft: "16px",
                        border: "none",
                        borderRadius: "0px",
                        backgroundColor: "#E4E4E4",
                        color: theme.palette.text.primary,
                        ":hover": {
                          bgcolor: "#E4E4E4",
                        },
                      }}
                    >
                      <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
                        {dashboard.title}
                      </Typography>
                      {dayjs(dashboard.updated_at).isToday() && (
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 300,
                            marginTop: "2px",
                            color: theme.palette.text.secondary,
                          }}
                        >
                          {" Last updated today"}
                        </Typography>
                      )}

                      {dayjs(dashboard.updated_at).isYesterday() && (
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 300,
                            marginTop: "2px",
                            color: theme.palette.text.secondary,
                          }}
                        >
                          {" Last updated yesterday"}
                        </Typography>
                      )}

                      {!dayjs(dashboard.updated_at).isToday() &&
                        !dayjs(dashboard.updated_at).isYesterday() && (
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 300,
                              marginTop: "2px",
                              color: theme.palette.text.secondary,
                            }}
                          >
                            {" Last updated on "}{" "}
                            {dayjs(dashboard.updated_at).format("DD MMM YYYY")}
                          </Typography>
                        )}
                    </Button>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          )
        )}
      </Box>
    </Drawer>
  );

  return (
    <Box sx={{ display: "flex" }}>
      {drawer()}
      <Main open={drawerOpen}>{children}</Main>
    </Box>
  );
}
