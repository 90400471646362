import {
  Autocomplete,
  CircularProgress,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DropdownProps } from "./FinancialAnalysis/types";
import theme from "../utils/theme";

const SingleSelectDropdown: React.FC<
  DropdownProps & {
    onSelect: (selectedValue: string) => void;
    selectedValue: string | null | undefined;
  }
> = ({
  label,
  placeholder,
  options,
  onSelect,
  selectedValue,
  loading,
  disabled,
  error,
}) => {
  const theme = useTheme();
  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    const selectedValue = value ? value.value : null;
    onSelect(selectedValue);
  };

  return (
    <>
      <Typography
        variant="h6"
        sx={{
          fontSize: "12px",
          fontWeight: 400,
          color: theme.palette.text.primary,
          mb: "8px",
        }}
      >
        {label}
      </Typography>
      <div style={{ position: "relative" }}>
        <Autocomplete
          sx={{ mb: "14px" }}
          options={options}
          getOptionKey={(option) => option.label + option.value}
          value={
            options.find((option) => option.value === selectedValue) || null
          }
          onChange={handleChange}
          autoComplete={true}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={placeholder}
              size="small"
              error={Boolean(error)}
              helperText={error}
            />
          )}
        />
        {loading && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 999,
            }}
          >
            <CircularProgress size={24} />
          </div>
        )}
      </div>
    </>
  );
};

const MultiSelectDropdown: React.FC<
  DropdownProps & {
    onSelect: (selectedValues: string[], selectedLabels: string[]) => void;
    selectedValues: string[];
  }
> = ({ label, placeholder, options, onSelect, selectedValues, loading }) => {
  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    const selectedValues = value.map((item: any) => item.value);
    const selectedLabels = value.map((item: any) => item.label);
    onSelect(selectedValues, selectedLabels);
  };

  return (
    <>
      <Typography
        variant="h6"
        sx={{
          fontSize: "12px",
          fontWeight: 400,
          color: theme.palette.text.primary,
          mb: "8px",
        }}
      >
        {label}
      </Typography>
      <div style={{ position: "relative" }}>
        <Autocomplete
          sx={{
            mb: "14px",
            ...(selectedValues.length > 0 && {
              "&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment":
                {
                  height: "100%",
                  paddingTop: "8px",
                },
            }),
          }}
          multiple
          options={options}
          disableCloseOnSelect
          getOptionKey={(option) => option.label + option.value}
          value={options.filter((option) =>
            selectedValues.includes(option.value)
          )}
          onChange={handleChange}
          ListboxProps={{
            style: {
              maxHeight: "150px",
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              variant="outlined"
              placeholder={placeholder}
              sx={{
                "& .MuiInputBase-root": {
                  maxHeight: "100px",
                  position: "static",
                  overflowY: "auto",
                },
              }}
            />
          )}
        />

        {loading && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 999,
            }}
          >
            <CircularProgress size={24} />
          </div>
        )}
      </div>
    </>
  );
};

export { SingleSelectDropdown, MultiSelectDropdown };
