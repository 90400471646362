import { CustomDashboardProps } from "../utils/types";
import FinancialAnalysisDashboard from "../components/FinancialAnalysis";

export const dashboard: CustomDashboardProps = {
  title: "Profitabilty With Grade Average Salary",
  charts: [],
  filters: [],
  Component: FinancialAnalysisDashboard,
  showPreviousYearMenu: true,
  showMonthWiseToggle:true,
};