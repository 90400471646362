import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Modal,
  Pagination,
  CircularProgress,
  Tooltip,
  Slide,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EmailIcon from "@mui/icons-material/Email";
import style from "./styles/financialOverlay";
import { baseUrl } from "../../config";
import axiosConfig from "../../utils/axiosConfig";
import {
  columnNameKeys,
  defaultRowCount,
  paginationNames,
  reportType,
} from "./constants";
import OverlayTableSection from "./components/OverlayTableComponent";
import {
  OverlayResponseData,
  ProjectData,
  InvoiceDetailsData,
  SalaryDetailsData,
  OtherExpenseDetailsData,
  ShowLoader,
  CurrentPage,
  CardData,
  ShowDetails,
  AvailableTraineeDetailsData,
} from "./types";
import { useApp } from "../../contexts/AppContext";

const OverlayComponent = React.memo(
  ({
    data,
    isOpen,
    handleClose,
    dashboardId,
    report_type,
    seletedReportvalue,
  }: {
    data: CardData;
    isOpen: boolean;
    handleClose: () => void;
    dashboardId: number;
    report_type: string | undefined;
    seletedReportvalue?: string | undefined;
  }) => {
    const [details, setDetails] = useState<OverlayResponseData>({});
    const [projects, setProjects] = useState<ProjectData[]>([]);
    const [invoiceDetailsData, setInvoiceDetailsData] =
      useState<InvoiceDetailsData>({});
    const [salaryDetails, setSalaryDetails] = useState<SalaryDetailsData>({});
    const [otherExpenseDetails, setOtherExpenseDetails] =
      useState<OtherExpenseDetailsData>({});
    const [availableTraineeDetails, setAvailableTraineeDetails] =
      useState<AvailableTraineeDetailsData>({});
    const { selectedFilter } = useApp();
    const [showLoader, setShowLoader] = useState<ShowLoader>({
      invoice: true,
      salary_expense: true,
      other_expense: true,
      projects: true,
      initialData: true,
      available_trainee_expense: true,
    });
    const [currentPage, setCurrentPage] = useState<CurrentPage>({
      invoice: 1,
      salary_expense: 1,
      other_expense: 1,
      project: 1,
      available_trainee: 1,
    });
    const [isShowDetails, setIsShowDetails] = useState<ShowDetails>({
      invoice: false,
      salary_expense: false,
      other_expense: false,
      available_trainee: false,
    });

    const tableData = {
      invoice: {
        name: "Invoice",
        totalHeading: "Total Invoiced Amount:",
        tableHeading1: "Invoice Number",
        tableHeading2: "Invoice Date",
        tableHeading3: "Amount",
      },
      salaryExpense: {
        name: "Salary Expense",
        totalHeading: "Total Salary Expense:",
        tableHeading1:
          report_type === reportType.project ? "Allocation Type" : "Name",
        tableHeading2: "Hours",
        tableHeading3: "Amount",
      },
      otherExpense: {
        name: "Other Expense",
        totalHeading: "Total Other Expenses:",
        tableHeading1: "Expense Detail",
        tableHeading2: "Date",
        tableHeading3: "Amount",
      },
      availableTrainee: {
        name: "Available/Trainee Salary Expense",
        totalHeading: "Total Expense:",
        tableHeading1: "Name",
        tableHeading2: "Hours",
        tableHeading3: "Amount",
      },
    };
    const [openModal, setOpenModal] = useState<boolean>(isOpen);
    useEffect(() => {
      const fetchData = async () => {
        try {
          if (![25, 30].includes(dashboardId)) {
            return null;
          }
          const endpoint =
            report_type === reportType.customer
              ? "project-overview"
              : report_type === reportType.project
              ? "project-employee-overview"
              : "customer-overview";

          const idParam =
            report_type === reportType.customer
              ? "project_id"
              : report_type === reportType.project
              ? "employee_id"
              : "customer_id";

          const additionalParams =
            report_type === reportType.project
              ? `&project_id=${seletedReportvalue || ""}`
              : "";

          let response = await axiosConfig({
            method: "get",
            url: `${baseUrl}/${
              dashboardId === 25
                ? "financial-overview"
                : "financial-overview-grade-average"
            }/${endpoint}?dashboard_id=${dashboardId}&year=${
              data.year || ""
            }&${idParam}=${data.customerId}&month=${
              data.monthIndex
            }${additionalParams}`,
          });
          if (response.status === 200 && response.data.data) {
            const responseData = response.data.data;
            setDetails(responseData);
            setProjects(responseData.projects);
          }
          setShowLoader((prevState) => ({
            ...prevState,
            projects: false,
            initialData: false,
          }));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      if (
        data.customerId &&
        data.year &&
        data.month &&
        data.monthIndex &&
        dashboardId
      ) {
        fetchData();
        fetchProjectsData(1);
      }
    }, [data.customerId, data.year, data.month, data.monthIndex, dashboardId]);

    useEffect(() => {
      switch (data.columnNameKey) {
        case columnNameKeys.otherExpense:
          handlePageChange(1, paginationNames.otherExpense);
          break;
        case columnNameKeys.revenue:
          handlePageChange(1, paginationNames.invoice);
          break;
        case columnNameKeys.salaryExpense:
          handlePageChange(1, paginationNames.salaryExpense);
          break;
        default: {
          console.warn("Unknown page name:", data.columnNameKey);
          break;
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.columnNameKey]);

    useEffect(() => {
      const showDetailData = {
        invoice: false,
        salary_expense: false,
        other_expense: false,
      };
      switch (data.columnNameKey) {
        case columnNameKeys.otherExpense:
          showDetailData.other_expense =
            checkNullValue(details.other_expense?.amount) !== "NA";
          break;
        case columnNameKeys.revenue:
          showDetailData.invoice =
            checkNullValue(details.revenue?.amount) !== "NA";
          break;
        case columnNameKeys.salaryExpense:
          showDetailData.salary_expense =
            checkNullValue(details.salary_expense?.amount) !== "NA";
          break;
        default: {
          break;
        }
      }
      setIsShowDetails(showDetailData);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      data.columnNameKey,
      details.other_expense?.amount,
      details.salary_expense?.amount,
      details.revenue?.amount,
      data.key,
    ]);

    const handlePageChange = (newPage: number, pageName: string) => {
      setCurrentPage((prevState) => ({
        ...prevState,
        [pageName]: newPage,
      }));
      switch (pageName) {
        case paginationNames.project:
          fetchProjectsData(newPage);
          break;
        case paginationNames.invoice:
          fetchInvoices(newPage);
          break;
        case paginationNames.otherExpense:
          fetchOtherExpenseData(newPage);
          break;
        case paginationNames.salaryExpense:
          fetchSalaryExpenseData(newPage);
          break;
        case paginationNames.availableTraineeExpense:
          fetchAvailableTraineeData(newPage);
          break;
        default: {
          console.warn("Unknown page name:", pageName);
          break;
        }
      }
    };

    const convertedDetails = {
      invoice:
        invoiceDetailsData?.invoice_details &&
        invoiceDetailsData?.invoice_details.map((inv) => ({
          column1: inv.invoice_number,
          column2: inv.invoice_date,
          column3: inv.amount.toLocaleString("en-IN"),
        })),
      salary_expense:
        salaryDetails.salary_expense &&
        salaryDetails.salary_expense.map((inv) => ({
          column1: inv.employee_name,
          column2: inv.hours_worked.toString(),
          column3: inv.amount.toLocaleString("en-IN"),
          allocationTypes: inv.allocation_type,
        })),
      other_expense:
        otherExpenseDetails.other_expense &&
        otherExpenseDetails.other_expense.map((inv) => ({
          column1: inv.expense_detail,
          column2: inv.expense_date,
          column3: inv.amount.toLocaleString("en-IN"),
        })),
      available_trainee:
        availableTraineeDetails.available_trainee &&
        availableTraineeDetails.available_trainee.map((item) => ({
          column1: item.employee_name,
          column2: item.hours_worked.toString(),
          column3: item.amount.toLocaleString("en-IN"),
          allocationTypes: item.allocation_type,
        })),
    };

    const fetchSalaryExpenseData = async (page: number) => {
      setShowLoader((prevState) => ({
        ...prevState,
        salary_expense: true,
      }));
      try {
        if (![25, 30].includes(dashboardId)) {
          return null;
        }
        const endpoint =
          report_type === reportType.customer
            ? "project-overview"
            : report_type === reportType.project
            ? "project-employee-overview"
            : "customer-overview";

        const idParam =
          report_type === reportType.customer
            ? "project_id"
            : report_type === reportType.project
            ? "employee_id"
            : "customer_id";

        const additionalParams =
          report_type === reportType.project
            ? `&project_id=${seletedReportvalue || ""}`
            : "";

        let response = await axiosConfig({
          method: "get",
          url: `${baseUrl}/${
            dashboardId === 25
              ? "financial-overview"
              : "financial-overview-grade-average"
          }/${endpoint}/salary-expenses?dashboard_id=${dashboardId}&year=${
            data.year ? data.year : ""
          }&${idParam}=${data.customerId}&month=${data.monthIndex}&page_size=${
            defaultRowCount.project
          }&page=${page}&${
            report_type === reportType.customer ? "salary_type=accountable" : ""
          }${additionalParams}`,
        });
        if (response.status === 200 && response.data.data) {
          setSalaryDetails({
            count: response.data.count,
            salary_expense: response.data.data,
          });
        }
        setShowLoader((prevState) => ({
          ...prevState,
          salary_expense: false,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
        setShowLoader((prevState) => ({
          ...prevState,
          salary_expense: false,
        }));
      }
    };

    const fetchOtherExpenseData = async (currentPage: number) => {
      const endpoint =
        report_type === reportType.customer
          ? "project-overview"
          : report_type === reportType.project
          ? "project-employee-overview"
          : "customer-overview";

      const idParam =
        report_type === reportType.customer
          ? "project_id"
          : report_type === reportType.project
          ? "employee_id"
          : "customer_id";

      const additionalParams =
        report_type === reportType.project
          ? `&project_id=${seletedReportvalue || ""}`
          : "";
      setShowLoader((prevState) => ({
        ...prevState,
        other_expense: true,
      }));
      if (![25, 30].includes(dashboardId)) {
        return null;
      }
      axiosConfig({
        method: "get",
        url: `${baseUrl}/financial-overview/${endpoint}/other-expenses?dashboard_id=${dashboardId}&page_size=${
          defaultRowCount.other_expense
        }&page=${currentPage}&year=${data.year ? data.year : ""}&${idParam}=${
          data.customerId
        }&month=${data.monthIndex}${additionalParams}`,
      })
        .then((response) => {
          setShowLoader((prevState) => ({
            ...prevState,
            other_expense: false,
          }));
          if (response.status === 200 && response.data.data) {
            setOtherExpenseDetails({
              count: response.data.count,
              other_expense: response.data.data,
            });
          }
        })
        .catch((error) => {
          setShowLoader((prevState) => ({
            ...prevState,
            other_expense: false,
          }));
          console.error("Error fetching data:", error);
        });
    };

    const fetchAvailableTraineeData = async (currentPage: number) => {
      setShowLoader((prevState) => ({
        ...prevState,
        other_expense: true,
      }));
      if (![25, 30].includes(dashboardId)) {
        return null;
      }
      axiosConfig({
        method: "get",
        url: `${baseUrl}/financial-overview/${
          report_type === reportType.customer
            ? "project-overview/salary-expenses"
            : "customer-overview/available-trainee-salary-expenses"
        }?dashboard_id=${dashboardId}&page_size=${
          defaultRowCount.available_trainee_details
        }&page=${currentPage}&year=${data.year ? data.year : ""}&${
          report_type === reportType.customer ? "project_id" : "customer_id"
        }=${data.customerId}&month=${data.monthIndex}&${
          report_type === reportType.customer
            ? "salary_type=available_trainee"
            : ""
        }`,
      })
        .then((response) => {
          setShowLoader((prevState) => ({
            ...prevState,
            available_trainee_expense: false,
          }));
          if (response.status === 200 && response.data.data) {
            setAvailableTraineeDetails({
              count: response.data.count,
              available_trainee: response.data.data,
            });
          }
        })
        .catch((error) => {
          setShowLoader((prevState) => ({
            ...prevState,
            available_trainee_expense: false,
          }));
          console.error("Error fetching data:", error);
        });
    };

    const fetchProjectsData = async (page: number) => {
      setShowLoader((prevState) => ({
        ...prevState,
        projects: true,
      }));
      try {
        if (![25, 30].includes(dashboardId)) {
          return null;
        }
        let response = await axiosConfig({
          method: "get",
          url: `${baseUrl}/financial-overview/customer-overview/projects?dashboard_id=${dashboardId}&year=${
            data.year ? data.year : ""
          }&customer_id=${data.customerId}&month=${data.monthIndex}&page_size=${
            defaultRowCount.project
          }&page=${page}`,
        });
        const responseData = response.data.data;
        setProjects(responseData);
        setShowLoader((prevState) => ({
          ...prevState,
          projects: false,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchInvoices = async (page: number) => {
      setShowLoader((prevState) => ({
        ...prevState,
        invoice: true,
      }));
      try {
        if (![25, 30].includes(dashboardId)) {
          return null;
        }
        let response = await axiosConfig({
          method: "get",
          url: `${baseUrl}/financial-overview/customer-overview/invoices?dashboard_id=${dashboardId}&year=${
            data.year ? data.year : ""
          }&customer_id=${data.customerId}&month=${data.monthIndex}&page_size=${
            defaultRowCount.project
          }&page=${page}`,
        });
        if (response.status === 200 && response.data.data) {
          setInvoiceDetailsData({
            count: response.data.count,
            invoice_details: response.data.data,
          });
        }
        setShowLoader((prevState) => ({
          ...prevState,
          invoice: false,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
        setShowLoader((prevState) => ({
          ...prevState,
          invoice: false,
        }));
      }
    };

    const checkNullValue = (value: any, emptyValue: string = "NA") => {
      return value !== null && value !== undefined ? value : emptyValue;
    };

    const getPaginationCount = (
      totalCount: number | undefined,
      countPerPage: number
    ) => {
      return totalCount ? Math.ceil(totalCount / countPerPage) : 1;
    };

    return (
      <Modal
        open={isOpen}
        onClose={() => {
          handleClose();
          setOpenModal(false);
        }}
        onClick={(e) => e.stopPropagation()}
        sx={{
          "&.MuiBox-root": {
            outline: "none !important",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0)",
            width: "575px",
            left: "initial",
          },
          "&.MuiModal-root": {
            width: "575px",
            left: "initial",
          },
        }}
      >
        <Slide
          direction="left"
          in={openModal}
          mountOnEnter
          unmountOnExit
          timeout={{ enter: 300, exit: 300 }}
          easing={{ enter: "ease-in", exit: "ease-out" }}
        >
          <Box sx={style.modal}>
            <Box sx={style.container}>
              <Box sx={style.header}>
                <Box>
                  <Typography
                    color="text.primary"
                    sx={style.header.accountName}
                  >
                    {data.customer}
                  </Typography>
                  <Typography color="text.primary" sx={style.header.month}>
                    {data.month}, {data.year}
                  </Typography>
                </Box>
                <IconButton
                  sx={style.header.arrow}
                  color="primary"
                  aria-label="right arrow"
                  onClick={() => {
                    handleClose();
                    setOpenModal(false);
                  }}
                >
                  <ArrowForwardIcon />
                </IconButton>
              </Box>
              {!showLoader.initialData && (
                <Box
                  sx={{
                    ...style.managerDetails.container,
                    ...(details?.customer_details?.account_manager ||
                    details?.customer_details?.email ||
                    details?.customer_details?.since ||
                    details?.customer_details?.type
                      ? {}
                      : style.managerDetails.container.noData),
                  }}
                >
                  <Box
                    sx={{ ...style.managerDetails.row, marginBottom: "4px" }}
                  >
                    {details?.customer_details?.account_manager && (
                      <Box sx={style.flexRow}>
                        <Typography
                          color="text.primary"
                          sx={[style.detailTitle, style.maxSpace]}
                        >
                          Account Manager:
                        </Typography>
                        <Typography
                          color="text.primary"
                          sx={{
                            ...style.detailText,
                            marginRight: "12px",
                            ...style.fillSpace,
                          }}
                        >
                          {details?.customer_details?.account_manager}
                        </Typography>
                      </Box>
                    )}
                    {details.customer_details?.email && (
                      <Box sx={style.flexRow}>
                        <Box aria-label="email">
                          <EmailIcon
                            sx={{
                              width: "12px",
                              height: "10px",
                              color: "#76A4FD",
                              marginRight: "3px",
                            }}
                          />
                        </Box>
                        <Typography color="text.primary" sx={style.detailText}>
                          {details.customer_details?.email}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box sx={style.managerDetails.row}>
                    {details.customer_details?.since && (
                      <Box sx={style.flexRow}>
                        <Typography color="text.primary" sx={style.detailTitle}>
                          Since:
                        </Typography>
                        <Typography
                          color="text.primary"
                          sx={{ ...style.detailText, marginRight: "12px" }}
                        >
                          {details.customer_details?.since}
                        </Typography>
                      </Box>
                    )}
                    {details.customer_details?.type && (
                      <Box sx={style.flexRow}>
                        <Typography color="text.primary" sx={style.detailTitle}>
                          Type:
                        </Typography>
                        <Typography color="text.primary" sx={style.detailText}>
                          {details.customer_details?.type}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}

              {Array.isArray(details?.pm_details) &&
                details.pm_details.map((pm, index) => (
                  <Box
                    sx={{
                      ...style.managerDetails.container,
                      ...(pm.project_manager || pm.company_email
                        ? {}
                        : style.managerDetails.container.noData),
                    }}
                  >
                    <Box
                      key={pm.space_employee_id || index}
                      sx={{
                        ...style.managerDetails.row,
                        marginBottom: "4px",
                      }}
                    >
                      <Box sx={style.flexRow}>
                        <Typography
                          color="text.primary"
                          sx={[style.detailTitle, style.maxSpace]}
                        >
                          Project Manager:
                        </Typography>
                        <Typography
                          color="text.primary"
                          sx={{
                            ...style.detailText,
                            marginRight: "12px",
                            ...style.fillSpace,
                          }}
                        >
                          {pm.project_manager}
                        </Typography>
                      </Box>
                      <Box sx={style.flexRow}>
                        <Box aria-label="email">
                          <EmailIcon
                            sx={{
                              width: "12px",
                              height: "10px",
                              color: "#76A4FD",
                              marginRight: "3px",
                            }}
                          />
                        </Box>
                        <Typography color="text.primary" sx={style.detailText}>
                          {pm.company_email}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}

              {details?.employee_details && (
                <Box sx={style.managerDetails.container}>
                  <Box sx={style.managerDetails.row}>
                    <Box sx={style.flexRow}>
                      <Typography
                        color="text.primary"
                        sx={[style.detailTitle, style.maxSpace]}
                      >
                        Name:
                      </Typography>
                      <Typography
                        color="text.primary"
                        sx={{
                          ...style.detailText,
                          marginRight: "12px",
                          ...style.fillSpace,
                        }}
                      >
                        {details.employee_details.name}
                      </Typography>
                    </Box>
                    <Box sx={style.flexRow}>
                      <Box aria-label="email">
                        <EmailIcon
                          sx={{
                            width: "12px",
                            height: "10px",
                            color: "#76A4FD",
                            marginRight: "3px",
                          }}
                        />
                      </Box>
                      <Typography color="text.primary" sx={style.detailText}>
                        {details.employee_details.company_email}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              {report_type !== reportType.customer &&
                report_type !== reportType.project && (
                  <Box sx={style.tableContainer}>
                    <Typography
                      color="text.primary"
                      sx={{ ...style.heading, ...style.mb10 }}
                    >
                      Projects
                    </Typography>
                    {showLoader.projects && (
                      <Box sx={style.circularLoaderBox}>
                        <CircularProgress
                          size={20}
                          sx={style.circularLoaderBox.loader}
                        />
                      </Box>
                    )}
                    {projects && projects.length > 0
                      ? projects.map((row, index) => (
                          <Box
                            key={index}
                            sx={[
                              style.tableContainer.row,
                              index + 1 !== projects.length && {
                                borderBottom: "1px solid #ECEFF3",
                              },
                            ]}
                          >
                            <Typography
                              color="text.primary"
                              sx={{ ...style.detailText, flex: 0.7 }}
                            >
                              {row.name}
                            </Typography>
                            <Tooltip
                              title={row.project_managers.join(", ")}
                              placement="top-end"
                              arrow
                            >
                              <Typography
                                color="text.primary"
                                sx={{
                                  ...style.detailTitle,
                                  ...style.flex1,
                                  ...style.managerName,
                                }}
                                textAlign="center"
                              >
                                {row.project_managers.join(", ")}
                              </Typography>
                            </Tooltip>
                          </Box>
                        ))
                      : !showLoader.projects &&
                        !details.projects_count && (
                          <Typography
                            color="text.primary"
                            sx={style.detailText}
                            textAlign={"center"}
                          >
                            No projects available
                          </Typography>
                        )}
                    <Box sx={{ my: "8px" }}>
                      {details.projects_count &&
                      details.projects_count > defaultRowCount.project ? (
                        <Pagination
                          count={Math.ceil(
                            details.projects_count / defaultRowCount.project
                          )}
                          page={currentPage.project}
                          onChange={(event, newPage) =>
                            handlePageChange(newPage, paginationNames.project)
                          }
                          size="small"
                          variant="outlined"
                          shape="rounded"
                          sx={{
                            "& .MuiPaginationItem-root": {
                              color: "gray",
                              "&.Mui-selected": {
                                backgroundColor: "#4E4E4E",
                                color: "white",
                              },
                            },
                            "& .MuiPaginationItem-icon": {
                              color: "#E6292E",
                            },
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </Box>
                  </Box>
                )}

              {report_type !== reportType.customer &&
                report_type !== reportType.project && (
                  <OverlayTableSection
                    count={getPaginationCount(
                      invoiceDetailsData.count,
                      defaultRowCount.invoice_details
                    )}
                    currentPage={currentPage.invoice}
                    header={tableData.invoice}
                    data={convertedDetails.invoice}
                    showLoader={showLoader.invoice}
                    showDetails={isShowDetails.invoice}
                    totalAmount={checkNullValue(
                      details.revenue?.amount
                    ).toLocaleString("en-IN")}
                    handlePageChange={(event, newPage) =>
                      handlePageChange(newPage, paginationNames.invoice)
                    }
                    disableDetails={
                      checkNullValue(details.revenue?.amount) === "NA"
                    }
                    toggleIsShowDetails={() =>
                      setIsShowDetails({
                        invoice: !isShowDetails.invoice,
                      })
                    }
                  />
                )}

              <OverlayTableSection
                count={getPaginationCount(
                  salaryDetails.count,
                  defaultRowCount.salary_expense
                )}
                currentPage={currentPage.salary_expense}
                header={tableData.salaryExpense}
                data={convertedDetails.salary_expense}
                showLoader={showLoader.salary_expense}
                showDetails={isShowDetails.salary_expense}
                totalAmount={checkNullValue(
                  details.salary_expense?.amount
                ).toLocaleString("en-IN")}
                handlePageChange={(event, newPage) =>
                  handlePageChange(newPage, paginationNames.salaryExpense)
                }
                disableDetails={
                  checkNullValue(details.salary_expense?.amount) === "NA"
                }
                toggleIsShowDetails={() =>
                  setIsShowDetails({
                    salary_expense: !isShowDetails.salary_expense,
                  })
                }
              />

              <OverlayTableSection
                count={getPaginationCount(
                  otherExpenseDetails.count,
                  defaultRowCount.other_expense
                )}
                currentPage={currentPage.other_expense}
                header={tableData.otherExpense}
                data={convertedDetails.other_expense}
                showLoader={showLoader.other_expense}
                showDetails={isShowDetails.other_expense}
                totalAmount={checkNullValue(
                  details.other_expense?.amount
                ).toLocaleString("en-IN")}
                handlePageChange={(event, newPage) =>
                  handlePageChange(newPage, paginationNames.otherExpense)
                }
                disableDetails={
                  checkNullValue(details.other_expense?.amount) === "NA"
                }
                toggleIsShowDetails={() =>
                  setIsShowDetails({
                    other_expense: !isShowDetails.other_expense,
                  })
                }
              />
              <Box sx={style.grossProfitSection}>
                <Typography
                  color="text.primary"
                  sx={{ ...style.heading, ...style.mb10 }}
                >
                  Gross Profit
                </Typography>
                <Box
                  sx={{
                    ...style.tableContainer.header,
                    textAlign: "center",
                  }}
                >
                  <Typography
                    color="text.primary"
                    sx={{
                      ...style.detailTitle,
                      paddingTop: "8px",
                      paddingBottom: "4px",
                      marginRight: "0px",
                    }}
                  >
                    {details.gross_profit?.amount
                      ? "Total Gross Profit"
                      : "No Total Gross Profit Available"}
                  </Typography>
                  <Typography
                    color="text.primary"
                    sx={{
                      ...style.heading,
                      fontSize: "22px",
                      paddingBottom: "8px",
                    }}
                  >
                    {details.gross_profit?.amount?.toLocaleString("en-IN")}
                  </Typography>
                </Box>
              </Box>
              {report_type !== reportType.project && (
                <OverlayTableSection
                  count={getPaginationCount(
                    availableTraineeDetails.count,
                    defaultRowCount.available_trainee_details
                  )}
                  currentPage={currentPage.available_trainee}
                  header={tableData.availableTrainee}
                  data={convertedDetails.available_trainee}
                  showLoader={showLoader.available_trainee_expense}
                  showDetails={isShowDetails.available_trainee}
                  totalAmount={checkNullValue(
                    details.available_trainee_salary_expense?.amount
                  ).toLocaleString("en-IN")}
                  handlePageChange={(event, newPage) =>
                    handlePageChange(
                      newPage,
                      paginationNames.availableTraineeExpense
                    )
                  }
                  disableDetails={
                    checkNullValue(
                      details.available_trainee_salary_expense?.amount
                    ) === "NA"
                  }
                  toggleIsShowDetails={() =>
                    setIsShowDetails({
                      available_trainee: !isShowDetails.available_trainee,
                    })
                  }
                  showWarning={true}
                />
              )}
              <Box sx={{ height: "20px", flexShrink: 0 }} />
            </Box>
          </Box>
        </Slide>
      </Modal>
    );
  }
);

export default OverlayComponent;
