import {
  Box,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  StickyTableCell,
  StickyTableHeadCell,
  StyledInnerTableRow,
  StyledTableCell,
} from "./TableComponents";
import React, { useEffect, useRef, useState } from "react";
import { AxiosRequestConfig } from "axios";
import { baseUrl } from "../../../config";
import { useFetch } from "../../../hooks";
import {
  CustomerData,
  CustomerTableHeadProps,
  CustomerWiseTableProps,
  ExpandedCustomerData,
  Filter,
  MonthlyData,
  PaginationViewProps,
} from "../types";
import ExpandIcon from "../../../assets/img/more.svg";
import LessIcon from "../../../assets/img/less.svg";
import axiosConfig from "../../../utils/axiosConfig";
import style from "../styles/financialAnalysis";
import { scrollbarStyle } from "../../../styles";
import { useApp } from "../../../contexts/AppContext";
import { reportType, columnNameKeys } from "../constants";

const CustomerWiseTable: React.FC<CustomerWiseTableProps> = ({
  dashboard_id,
  applied_filters,
  setLoading,
  isExpanded,
  from_date,
  to_date,
  setHideSummary,
  loading,
  openOverlay,
}) => {
  const [expandedCustomer, setExpandedCustomer] = useState<number | null>(null);
  const [expandedData, setExpandedData] = useState<
    Record<number, ExpandedCustomerData>
  >({});
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [customerRowsPerPage, setCustomerRowsPerPage] = useState(50);
  const [expandedCurrentPage, setExpandedCurrentPage] = useState(1);
  const [expandedCount, setExpandedCount] = useState(0);
  const rowRefs = useRef<(HTMLTableRowElement | null)[]>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const currentExpandedCustomer = useRef<HTMLTableRowElement>(null);
  const { selectedItem, selectedFilter, setSelectedFilter, monthToggle } =
    useApp();

  const customerWiseData = React.useMemo<AxiosRequestConfig | null>(() => {
    if (![25, 30].includes(dashboard_id)) {
      return null;
    }
    if (
      !selectedFilter ||
      selectedFilter.selectedLevel1?.label === reportType.overview ||
      selectedFilter.selectedLevel1?.label === "" ||
      selectedFilter.selectedLevel1?.label === undefined
    ) {
      return {
        method: "GET",
        url: `${baseUrl}/${
          dashboard_id === 25
            ? "financial-overview"
            : "financial-overview-grade-average"
        }/customer-wise?dashboard_id=${dashboard_id}&customer_id=${
          applied_filters.customer ? applied_filters.customer : ""
        }&from_date=${from_date}&to_date=${to_date}&page=${currentPage}&page_size=${rowsPerPage}&account_manager=${
          applied_filters.accountManager ? applied_filters.accountManager : ""
        }`,
      };
    } else {
      return {
        method: "GET",
        url: `${baseUrl}/${
          dashboard_id === 25
            ? "financial-overview"
            : "financial-overview-grade-average"
        }/customer-wise/by-report-type/${selectedFilter.selectedLevel1?.label.toLocaleLowerCase()}?dashboard_id=${dashboard_id}&entity_id=${
          selectedFilter.selectedLevel2?.value || ""
        }&from_date=${from_date}&to_date=${to_date}&page=${currentPage}&page_size=${rowsPerPage}`,
      };
    }
  }, [applied_filters, currentPage, rowsPerPage, selectedFilter]);

  const customerWiseDataObj = useFetch<{
    data: CustomerData[];
    message: string;
    count: number;
  } | null>(customerWiseData);

  useEffect(() => {
    setLoading(customerWiseDataObj.loading);
  }, [customerWiseDataObj.loading]);

  useEffect(() => {
    if (expandedCustomer) {
      setExpandedCustomer(null);
      setExpandedData([]);
      setHideSummary(false);
    }
  }, [applied_filters]);

  const getSubHeading = () => {
    switch (selectedFilter.selectedLevel1?.label) {
      case reportType.overview:
        return "Customers";
      case reportType.customer:
        return "Projects";
      case reportType.project:
        return "Employees";
      default:
        return "Customers";
    }
  };

  const handleCellClick = (
    customer: number,
    rowChange: boolean,
    newLimit: number,
    pageChange: boolean,
    newPage: number
  ) => {
    setLoading(true);
    if (expandedCustomer === customer && !rowChange && !pageChange) {
      setExpandedCustomer(null);
      setHideSummary(false);
      setLoading(false);
      setExpandedCurrentPage(1);
    } else {
      setExpandedCustomer(customer);
      setHideSummary(true);
      if (!pageChange) {
        setExpandedCurrentPage(1);
      }
      if (![25, 30].includes(dashboard_id)) {
        return null;
      }
      let drillDownUrl = "";
      if (
        selectedFilter.selectedLevel1?.label === reportType.overview ||
        (!selectedFilter.selectedLevel1 && !selectedFilter.selectedLevel2)
      ) {
        drillDownUrl = `${baseUrl}/${
          dashboard_id === 25
            ? "financial-overview"
            : "financial-overview-grade-average"
        }/customer-wise/expanded-view?dashboard_id=${dashboard_id}&customer_id=${customer}&from_date=${from_date}&to_date=${to_date}&page_size=${
          rowChange ? newLimit : 50
        }&page=${pageChange ? newPage : 1}`;
      } else {
        drillDownUrl = `${baseUrl}/${
          dashboard_id === 25
            ? "financial-overview"
            : "financial-overview-grade-average"
        }/customer-wise/expanded-view/by-report-type/${selectedFilter.selectedLevel1?.label.toLocaleLowerCase()}?dashboard_id=${dashboard_id}&report_type_id=${
          selectedFilter.selectedLevel2?.value || ""
        }&entity_id=${customer}&from_date=${from_date}&to_date=${to_date}&page_size=${
          rowChange ? newLimit : 50
        }&page=${pageChange ? newPage : 1}`;
      }
      axiosConfig({
        method: "get",
        url: drillDownUrl,
      })
        .then((response) => {
          if (response.status === 200 && response.data) {
            setExpandedData((prevData) => ({
              ...prevData,
              [customer]: response.data.data,
            }));
            setExpandedCount(response.data.count);
            setLoading(false);
          } else {
            setLoading(false);
            throw new Error("Failed to fetch the data.");
          }
        })
        .catch((error) => {
          console.error(error);
          throw new Error("An error occurred while fetching data");
        });
    }
  };

  useEffect(() => {
    if (expandedCustomer !== null) {
      scrollToCustomer(expandedCustomer);
    }
  }, [expandedCustomer]);

  const scrollToCustomer = (customerId: number) => {
    const rowElement = rowRefs.current[customerId];
    if (rowElement && containerRef.current) {
      const headerOffset = 58;
      const container = containerRef.current;
      const offsetTop = rowElement.offsetTop;
      const maxScrollTop = container.scrollHeight - container.clientHeight;
      let newScrollTop = offsetTop - headerOffset;

      if (newScrollTop > maxScrollTop) {
        newScrollTop = maxScrollTop;
      }
      container.scrollTo({
        top: newScrollTop,
        behavior: "smooth",
      });
    } else {
      console.log("Row or container not found");
    }
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const containerTop = containerRef.current.getBoundingClientRect().top;

      let lastStickyRow: HTMLTableRowElement | null = null;
      customerWiseDataObj?.data?.data.forEach((customer) => {
        const row = rowRefs.current[customer.id];
        if (row && expandedCustomer == customer.id) {
          const expandedCustomerBottom =
            currentExpandedCustomer.current?.getBoundingClientRect().bottom;
          if (
            expandedCustomerBottom &&
            expandedCustomerBottom > containerTop + 108
          ) {
            lastStickyRow = row;
          }
        }
      });

      Object.keys(rowRefs.current).forEach((customer: any) => {
        const row = rowRefs.current[customer];
        if (row) {
          const isSticky = row === lastStickyRow;
          row.style.position = isSticky ? "sticky" : "static";
          row.style.top = isSticky ? "56px" : "auto";
          row.style.zIndex = isSticky ? "2" : "auto";
          row.style.backgroundColor = isSticky ? "white" : "auto";
        }
      });
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      handleScroll();
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [customerWiseDataObj.data, expandedCustomer]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPage = (event: SelectChangeEvent<number>) => {
    setRowsPerPage(parseInt(event.target.value as string, 10));
    setCurrentPage(1);
  };

  const handleExpandedRowsChange = (
    event: SelectChangeEvent<number>,
    customer: number
  ) => {
    setCustomerRowsPerPage(parseInt(event.target.value as string, 10));
    setExpandedCurrentPage(1);
    handleCellClick(
      customer,
      true,
      parseInt(event.target.value as string, 10),
      false,
      1
    );
  };

  const handleExpandedPagechange = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
    customer: number
  ) => {
    setExpandedCurrentPage(newPage);
    handleCellClick(customer, false, customerRowsPerPage, true, newPage);
  };

  const data = customerWiseDataObj.data?.data;

  return (
    <Stack direction={"column"}>
      <Box
        ref={containerRef}
        sx={{
          ...scrollbarStyle.body,
          height: `${expandedCustomer ? "66vh" : "53vh"}`,
          overflow: "auto",
        }}
      >
        <Table size="small" stickyHeader>
          <TableHead>
            <CustomerTableHead tableType={getSubHeading()} />
          </TableHead>
          <TableBody>
            <CustomerTableBody
              data={data}
              expandedCustomer={expandedCustomer}
              handleCustomerClick={handleCellClick}
              expandedData={expandedData}
              handleCustomerRowsPerPageChange={handleExpandedRowsChange}
              expandedRowLimit={customerRowsPerPage}
              handleExpandedPageChange={handleExpandedPagechange}
              currentExpandedPage={expandedCurrentPage}
              expandedCount={expandedCount}
              rowRefs={rowRefs}
              expandedRowRef={currentExpandedCustomer}
              isExpanded={isExpanded}
              loading={loading}
              openOverlay={openOverlay}
              selectedFilter={selectedFilter}
              dashboardId={dashboard_id}
            />
          </TableBody>
        </Table>
      </Box>
      {customerWiseDataObj?.data?.data && (
        <PaginationView
          count={customerWiseDataObj?.data?.count}
          page={currentPage}
          handlePageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          handleRowsPerchange={handleRowsPerPage}
        />
      )}
    </Stack>
  );
};

const CustomerTableHead: React.FC<CustomerTableHeadProps> = ({ tableType }) => {
  return (
    <TableRow>
      <StickyTableHeadCell
        sx={{ py: 2, px: 3 }}
        noBorderTop
        noBorderBottom
        noBorderLeft
        textAlign="left"
        fontSize="16px"
      >
        {tableType}
      </StickyTableHeadCell>
      <StickyTableHeadCell
        fontSize="16px"
        noBorderTop
        noBorderBottom
        noBorderRight
        textAlign="left"
        sx={{ py: 2, px: 3, pb: "10px", zIndex: 1 }}
      >
        <span>Revenue</span>
        <span style={{ color: "#71767A" }}> (₹)</span>
      </StickyTableHeadCell>
      <StickyTableHeadCell
        fontSize="16px"
        noBorderTop
        noBorderBottom
        noBorderRight
        textAlign="left"
        sx={{ py: 2, px: 3, pb: "10px", zIndex: 1 }}
      >
        <span>Salary Expenses </span>
        <span style={{ color: "#71767A" }}> (₹)</span>
      </StickyTableHeadCell>
      <StickyTableHeadCell
        fontSize="16px"
        noBorderTop
        noBorderBottom
        noBorderRight
        textAlign="left"
        sx={{ py: 2, px: 3, pb: "10px", zIndex: 1 }}
      >
        <span>Other Expenses </span>
        <span style={{ color: "#71767A" }}> (₹)</span>
      </StickyTableHeadCell>
      <StickyTableHeadCell
        fontSize="16px"
        noBorderTop
        noBorderBottom
        textAlign="left"
        sx={{ py: 2, px: 3, pb: "10px", zIndex: 1 }}
      >
        <span>Gross Profit </span>
        <span style={{ color: "#71767A" }}> (₹)</span>
      </StickyTableHeadCell>
    </TableRow>
  );
};

const CustomerTableBody: React.FC<{
  data: CustomerData[] | undefined;
  expandedCustomer: number | null;
  handleCustomerClick: (
    customer: number,
    rowChange: boolean,
    newLimit: number,
    pageChange: boolean,
    newPage: number
  ) => void;
  expandedData: { [key: number]: any };
  handleCustomerRowsPerPageChange: (
    event: SelectChangeEvent<number>,
    customer: number
  ) => void;
  expandedRowLimit: number;
  handleExpandedPageChange: (
    event: React.ChangeEvent<unknown>,
    newPage: number,
    customer: number
  ) => void;
  currentExpandedPage: number;
  expandedCount: number;
  rowRefs: React.MutableRefObject<(HTMLTableRowElement | null)[]>;
  expandedRowRef: React.RefObject<HTMLTableRowElement>;
  isExpanded: boolean;
  loading: boolean;
  openOverlay: (
    year: string,
    detail: any,
    month: string,
    columnNameKey: string
  ) => void;
  selectedFilter: Filter;
  dashboardId: number;
}> = ({
  data,
  expandedCustomer,
  handleCustomerClick,
  expandedData,
  handleCustomerRowsPerPageChange,
  expandedRowLimit,
  handleExpandedPageChange,
  currentExpandedPage,
  expandedCount,
  rowRefs,
  expandedRowRef,
  isExpanded,
  loading,
  openOverlay,
  selectedFilter,
  dashboardId,
}) => {
  const canOpenOverlay = true;
  return (
    <>
      {data?.length === 0 ? (
        <TableRow sx={{ height: "52vh" }}>
          <StyledTableCell
            noBorderBottom
            noBorderLeft
            noBorderRight
            noBorderTop
            colSpan={5}
            align="center"
          >
            <Typography sx={{ textAlign: "center" }}>
              No Data Available
            </Typography>
          </StyledTableCell>
        </TableRow>
      ) : (
        data?.map((row, index) => (
          <React.Fragment key={row.id}>
            <TableRow ref={(el) => (rowRefs.current[row.id] = el)} key={row.id}>
              <StickyTableCell
                noBorderLeft
                noBorderTop
                hoverBackgroundColor="#DDE2E6"
                onClick={() =>
                  handleCustomerClick(row.id, false, expandedRowLimit, false, 1)
                }
                fontColor="#343A40"
                component="th"
                scope="row"
                textAlign="left"
                sx={{
                  py: 1,
                  px: 3,
                  width: "150px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "150px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {row.name}
                    <img
                      src={expandedCustomer === row.id ? LessIcon : ExpandIcon}
                      alt="expand icon"
                      style={{
                        marginLeft: 8,
                        lineHeight: "0px",
                      }}
                    />
                  </Box>
                  {expandedCustomer === row.id && (
                    <Box
                      sx={{
                        fontSize: "14px",
                        textAlign: "right",
                        lineHeight: "0px",
                        paddingY: "6px",
                      }}
                    >
                      {"Month - Year"}
                    </Box>
                  )}
                </Box>
              </StickyTableCell>
              <StyledTableCell
                noBorderTop
                noBorderLeft
                noBorderRight
                textAlign="left"
                sx={{
                  py: 1,
                  px: 3,
                  width: "150px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "150px",
                }}
                align="center"
              >
                {row.revenue.toLocaleString("en-IN")}
              </StyledTableCell>
              <StyledTableCell
                noBorderTop
                noBorderLeft
                noBorderRight
                textAlign="left"
                sx={{
                  py: 1,
                  px: 3,
                  width: "150px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "150px",
                }}
                align="center"
              >
                {row.salary_expense.toLocaleString("en-IN")}
              </StyledTableCell>
              <StyledTableCell
                noBorderTop
                noBorderLeft
                noBorderRight
                textAlign="left"
                sx={{
                  py: 1,
                  px: 3,
                  width: "150px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "150px",
                }}
                align="center"
              >
                {row.other_expense.toLocaleString("en-IN")}
              </StyledTableCell>
              <StyledTableCell
                noBorderTop
                noBorderLeft
                noBorderRight
                textAlign="left"
                sx={{
                  py: 1,
                  px: 3,
                  width: "150px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "150px",
                }}
                align="center"
              >
                {row.gross_profit.toLocaleString("en-IN")}
              </StyledTableCell>
            </TableRow>
            {expandedCustomer === row.id && expandedData[row.id] && (
              <StyledInnerTableRow ref={expandedRowRef}>
                <StyledTableCell colSpan={5} sx={{ p: 0 }} noBorderLeft>
                  <Table sx={{ borderCollapse: "separate" }} size="small">
                    <TableBody>
                      {Object.entries(expandedData[row.id]).map(
                        ([month, details], index) => {
                          const monthlyDetails = details as MonthlyData;
                          const [monthIndex, year] = month.split(" ");
                          return (
                            <TableRow key={index}>
                              <StickyTableCell
                                noBorderTop
                                noBorderLeft
                                fontColor={
                                  canOpenOverlay ? "#2190FF" : "#3E3F3F"
                                }
                                textAlign="right"
                                onClick={() =>
                                  openOverlay(
                                    year,
                                    {
                                      id: row.id,
                                      name: row.name,
                                    },
                                    monthIndex,
                                    columnNameKeys.account
                                  )
                                }
                                sx={{
                                  py: 1,
                                  px: 3,
                                  ...style.subTableText,
                                  width: "150px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "150px",
                                  ...(canOpenOverlay
                                    ? style.cursorPointer
                                    : style.cursorDefault),
                                  "&:hover": {
                                    background: canOpenOverlay ? "#DDE2E6" : "",
                                  },
                                }}
                              >
                                {month}
                              </StickyTableCell>
                              <StyledTableCell
                                noBorderTop
                                noBorderLeft
                                noBorderRight
                                textAlign="left"
                                onClick={() =>
                                  openOverlay(
                                    year,
                                    {
                                      id: row.id,
                                      name: row.name,
                                    },
                                    monthIndex,
                                    columnNameKeys.revenue
                                  )
                                }
                                sx={{
                                  py: 1,
                                  px: 3,
                                  width: "150px",
                                  ...style.subTableText,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "150px",
                                  ...(canOpenOverlay
                                    ? style.cursorPointer
                                    : style.cursorDefault),
                                  "&:hover": {
                                    background: canOpenOverlay ? "#DDE2E6" : "",
                                  },
                                }}
                              >
                                {monthlyDetails.revenue.toLocaleString("en-IN")}
                              </StyledTableCell>
                              <StyledTableCell
                                noBorderTop
                                noBorderLeft
                                noBorderRight
                                textAlign="left"
                                onClick={() =>
                                  openOverlay(
                                    year,
                                    {
                                      id: row.id,
                                      name: row.name,
                                    },
                                    monthIndex,
                                    columnNameKeys.salaryExpense
                                  )
                                }
                                sx={{
                                  py: 1,
                                  px: 3,
                                  ...style.subTableText,
                                  ...(canOpenOverlay
                                    ? style.cursorPointer
                                    : style.cursorDefault),
                                  "&:hover": {
                                    background: canOpenOverlay ? "#DDE2E6" : "",
                                  },
                                  width: "150px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "150px",
                                }}
                              >
                                {monthlyDetails.salary_expense.toLocaleString(
                                  "en-IN"
                                )}
                              </StyledTableCell>
                              <StyledTableCell
                                noBorderTop
                                noBorderLeft
                                noBorderRight
                                textAlign="left"
                                onClick={() =>
                                  openOverlay(
                                    year,
                                    {
                                      id: row.id,
                                      name: row.name,
                                    },
                                    monthIndex,
                                    columnNameKeys.otherExpense
                                  )
                                }
                                sx={{
                                  py: 1,
                                  px: 3,
                                  ...style.subTableText,
                                  ...(canOpenOverlay
                                    ? style.cursorPointer
                                    : style.cursorDefault),
                                  "&:hover": {
                                    background: canOpenOverlay ? "#DDE2E6" : "",
                                  },
                                  width: "150px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "150px",
                                }}
                              >
                                {monthlyDetails.other_expense.toLocaleString(
                                  "en-IN"
                                )}
                              </StyledTableCell>
                              <StyledTableCell
                                noBorderTop
                                noBorderLeft
                                noBorderRight
                                textAlign="left"
                                sx={{
                                  py: 1,
                                  px: 3,
                                  ...style.subTableText,
                                  width: "150px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "150px",
                                }}
                              >
                                {monthlyDetails.gross_profit.toLocaleString(
                                  "en-IN"
                                )}
                              </StyledTableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                    <TableRow>
                      <StyledTableCell
                        colSpan={5}
                        sx={{
                          p: 0,
                          backgroundColor: "#F5F5F5",
                        }}
                        noBorderRight
                        noBorderLeft
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            position: "sticky",
                            left: "0",
                            right: 0,
                            width: `calc(${
                              isExpanded ? "84vw" : "95.6vw"
                            } - 60px)`,
                            borderLeft: "0.5px solid rgba(0, 0, 0, 0.12)",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                pl: "30px",
                                fontSize: "14px",
                              }}
                            >
                              Show
                            </Typography>
                            <Select
                              size="small"
                              sx={{
                                mx: "12px",
                                height: "24px",
                                fontSize: "14px",
                                fontWeight: 400,
                                p: 0,
                                my: "6px",
                                backgroundColor: "#FFFFFF",
                              }}
                              value={expandedRowLimit}
                              onChange={(event) =>
                                handleCustomerRowsPerPageChange(event, row.id)
                              }
                              displayEmpty
                              inputProps={{
                                "aria-label": "Without label",
                              }}
                            >
                              <MenuItem value={50}>50</MenuItem>
                              <MenuItem value={100}>100</MenuItem>
                            </Select>
                          </Box>
                          <Pagination
                            count={Math.ceil(
                              (expandedCount || 0) / expandedRowLimit
                            )}
                            page={currentExpandedPage}
                            onChange={(event, newPage) =>
                              handleExpandedPageChange(event, newPage, row.id)
                            }
                            variant="outlined"
                            shape="rounded"
                            sx={{
                              "& .MuiPaginationItem-root": {
                                height: "24px",
                                color: "gray",
                                "&.Mui-selected": {
                                  backgroundColor: "#4E4E4E",
                                  color: "white",
                                },
                              },
                              "& .MuiPaginationItem-icon": {
                                color: "#E6292E",
                              },
                            }}
                          />
                        </Box>
                      </StyledTableCell>
                    </TableRow>
                  </Table>
                </StyledTableCell>
              </StyledInnerTableRow>
            )}
          </React.Fragment>
        ))
      )}
    </>
  );
};

const PaginationView: React.FC<PaginationViewProps> = ({
  count,
  page,
  handlePageChange,
  rowsPerPage,
  handleRowsPerchange,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        display: "flex",
        mt: "6px",
        p: 1,
        justifyContent: "space-between",
        borderRadius: "8px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            pl: "30px",
            fontSize: "14px",
          }}
        >
          Show
        </Typography>
        <Select
          size="small"
          sx={{
            mx: "12px",
            height: "24px",
            fontSize: "14px",
            fontWeight: 400,
            p: 1,
            backgroundColor: "#FFFFFF",
          }}
          value={rowsPerPage}
          onChange={(event) => handleRowsPerchange(event)}
          displayEmpty
          inputProps={{
            "aria-label": "Without label",
          }}
        >
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </Box>
      <Pagination
        count={Math.ceil((count || 0) / rowsPerPage)}
        page={page || 1}
        onChange={(event, newPage) => handlePageChange(event, newPage)}
        variant="outlined"
        shape="rounded"
        sx={{
          mr: "20px",
          "& .MuiPaginationItem-root": {
            height: "24px",
            color: "gray",
            "&.Mui-selected": {
              backgroundColor: "#4E4E4E",
              color: "white",
            },
          },
          "& .MuiPaginationItem-icon": {
            color: "#E6292E",
          },
        }}
      />
    </Box>
  );
};

export default CustomerWiseTable;
