const columnNameKeys = {
  revenue: "REVENUE",
  salaryExpense: "SALARY_EXPENSE",
  otherExpense: "OTHER_EXPENSE",
  account: "ACCOUNT",
};

const paginationNames = {
  project: "project",
  invoice: "invoice",
  salaryExpense: "salary_expense",
  otherExpense: "other_expense",
  availableTraineeExpense: "available_trainee",
};

const defaultRowCount = {
  project: 5,
  other_expense: 5,
  salary_expense: 5,
  invoice_details: 5,
  available_trainee_details: 5,
};

const reportType = {
  project: "Project",
  customer: "Customer",
  overview: "Overview",
};

const salary_expense_warning= "When determining the gross profit, these costs are not taken into account."

export { columnNameKeys, paginationNames, defaultRowCount, reportType, salary_expense_warning };
