const financialOverlayStyle = {
  modal: {
    position: "absolute",
    top: "75px",
    right: 0,
    bottom: 0,
    width: "575px",
    overflowY: "auto",
    display: "flex",
    zIndex: 9999,
    borderRadius: "8px 0 0 8px",
    backgroundColor: "#FFFFFF",
    boxShadow: "-4px 2px 6px 0px #00000040",
    outline: "none",
    maxHeight: "calc(100vh - 75px)",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "16px 20px 16px 20px",
   "& > :last-child": {
    marginBottom: "20px",
  },
    width: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
    accountName: {
      fontSize: "24px",
      fontWeight: 500,
      color: "#3E3F3F",
      lineHeight: "30.24px",
    },
    month: {
      fontSize: "14px",
      fontWeight: 400,
      color: "#7F7F7F",
    },
    arrow: {
      height: "24px",
      width: "24px",
      color: "#2190FF",
    },
  },
  managerDetails: {
    container: {
      bgcolor: "#E5FAFF",
      padding: "8px 16px 8px 16px",
      borderRadius: "4px",
      marginBottom: "20px",
      noData: {
        padding: "0px",
        bgcolor: "#FFFFFF",
        marginBottom: "0px",
      },
    },
    row: {
      display: "flex",
      flexDirection: "row",
    },
  },
  maxSpace: {
    flex: "0 1 auto",
    whiteSpace: "nowrap",
  },
  fillSpace: {
    flex: "1 1 auto",
  },
  detailTitle: {
    color: "#7F7F7F",
    fontWeight: 400,
    fontSize: "13px",
    marginRight: "4px",
  },
  detailText: {
    color: "#3E3F3F",
    fontWeight: 400,
    fontSize: "14px",
  },
  heading: {
    color: "#3E3F3F",
    fontWeight: 500,
    fontSize: "16px",
  },
  tableContainer: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    marginBottom: "20px",
    width: "100%",
    paddingTop: "4px",
    borderBottom: "1px solid #B4B3B3",
    header: {
      bgcolor: "#ECEFF3",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      padding: "2px 4px 2px 4px",
    },
  },
  flex1: {
    flex: 1,
  },
  flex_space: {
    flex: 1.6,
  },
  mb10: {
    marginBottom: "10px",
  },
  mb4: {
    marginBottom: "4px",
  },
  totalAmountContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "4px 8px 4px 8px",
    flex: 1,
    hideText: {
      color: "#008DF9",
      cursor: "pointer",
      fontWeight: 400,
      fontSize: "12px",
      "&.disabled": {
        cursor: "not-allowed",
        color: "grey.500",
      },
    },
    totalText: {
      color: "#6B6C6D",
      fontWeight: 500,
      fontSize: "14px",
    },
    totalAmount: {
      color: "#3E3F3F",
      fontWeight: 500,
      fontSize: "18px",
      marginLeft: "4px",
    },
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  circularLoaderBox: {
    textAlign: "center",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    loader: {
      color: "#E6292E",
      alignSelf: "center",
      margin: "10px",
    },
  },
  managerName: {
    textAlign: "end",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "300px",
  },
  relativePosition: {
    position: "relative",
  },
  warningBox: {
    backgroundColor: "#FEFFDF",
    p: "4px",
    marginBottom: "4px",
    justifyItems: "center",
  },
  warningIcon: {
    color: "#F70303",
    height: "14px",
    width: "14px",
    marginRight: "4px",
  },
  labelChip: {
    width: "45px",
    height: "15px",
    mr: "2px",
    mt: "1px",
    color: "#FFF",
    borderRadius: "4px",
  },
  grossProfitSection: {
    paddingBottom: "8px",
    borderBottom: "1px solid #B4B3B3",
    marginBottom: "20px",
  },
};

export default financialOverlayStyle;
